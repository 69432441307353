import React from "react";
import { Container, Row } from "react-bootstrap";
import { Title, SubTitle, Description, MainSection } from "./style";
const Privacypolicy = ({ title }) => {
    return (
        <MainSection>
            <Container className="w-75">
                <Row className="align-items-center">
                    <Title>{title}</Title>
                    <SubTitle>
                        Woorg respects your privacy and is committed to protecting your privacy
                    </SubTitle>
                    <Description>
                        This Privacy Policy applies to the information we obtained
                        through your use of <a href="https://www.woorg.com/ " target="_blank" rel="noreferrer">https://www.woorg.com/ </a> ("the Website" or
                        "Woorg"). By using the Website, you consent to having your
                        personal information that you provide directly to us or that
                        we collect through your use of the Website as described in
                        this Privacy Policy.
                        <br />
                        <br />
                        Please read the following thoroughly to understand our
                        policies and practices regarding your information collected
                        through the Website, through our marketing activities and
                        how we will treat it. If you do not agree with the below
                        policies and practices, you have the choice to not to browse
                        our Website or fill out a form on our Website.
                    </Description>
                    <SubTitle>
                        What information does the Website collect and how is it
                        used?
                    </SubTitle>
                    <Description>
                        We collect information that, alone or in combination with
                        other data, could be used to identify you ("Personal
                        Information"). Some of the Information we collect is stored
                        in a manner that cannot be linked back to you ("Non-Personal
                        Information").
                        <br />
                        <br />
                        Contact Information: We collect the following Personal
                        Information through our Website when you share that
                        information with us to ask us to contact you: first and last
                        name, email, and phone. We process this information in order
                        to contact you, to respond to your inquiries and to send you
                        marketing communications we believe may be relevant to you.
                        <br />
                        <br />
                        Automatically Collected Data: Our servers automatically
                        collect additional information when you access or use the
                        Website and record it in log files. This log data may
                        include the IP address, the date and time of use, browser
                        type and settings, information about browser configuration,
                        language preferences, and cookie data.
                    </Description>
                    <SubTitle>
                        Does Woorg disclose any Personal Information to third
                        parties?
                    </SubTitle>
                    <Description>
                        We do not sell, trade, or otherwise transfer to outside
                        parties your Personal Information.
                    </Description>
                    <SubTitle>Does the Website use cookies?</SubTitle>
                    <Description>
                        Yes. A cookie is a small piece of data sent from a website
                        and stored in the user's web browser while the user is
                        browsing. Cookies enable us to recognize your browser, and
                        capture and remember certain information. We use cookies to
                        compile aggregate data about site traffic and site
                        interaction so that we can offer better site experiences and
                        tools in the future. We may contract with third-party
                        service providers to assist us in better understanding our
                        site visitors. These service providers are not permitted to
                        use the information collected on our behalf except to help
                        us conduct and improve our business.
                    </Description>
                    <SubTitle>
                        Does your Website engage in online advertising and tracking?
                    </SubTitle>
                    <Description>
                        Yes. We may use cookies for retargeting online
                        advertisements.
                    </Description>
                    <SubTitle>Does your website use Google Analytics?</SubTitle>
                    <Description>
                        Yes, Woorg uses Google Analytics. To view how Google
                        collects and processes data, follow this link:
                        <a href="www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">www.google.com/policies/privacy/partners/</a>.
                    </Description>
                    <SubTitle>Can I opt-out of Cookies?</SubTitle>
                    <Description>
                        No,You need to agree to collect the cookies.
                    </Description>
                    <SubTitle>Can I opt-out of marketing communications?</SubTitle>
                    <Description>
                        If you wish to no longer receive marketing communications
                        from us, you may opt-out by clicking the "unsubscribe" link
                        found within Woorg emails. If you are located in the
                        European Economic Area (EEA), we will only send you
                        marketing information if you consent to us doing so at the
                        time you provide your Personally Identifiable Information to
                        us through our Website or any point thereafter.
                    </Description>
                    <SubTitle>
                        Does your Website link to third party sites?
                    </SubTitle>
                    <Description>
                        Our website may contain links to other sites that are not
                        owned or controlled by Woorg. Please be aware Woorg is not
                        responsible for the privacy practices or policies of others.
                        We encourage you to consult and read the privacy policies of
                        the other sites. This privacy policy applies only to
                        information collected by our website
                    </Description>
                    <SubTitle>
                        How does Woorg keep Personal Information secure?
                    </SubTitle>
                    <Description>
                        Woorg takes reasonable administrative, technical and
                        physical measures to safeguard your Personal Information
                        against loss, theft, and unauthorized access, use,
                        disclosure, alteration, or destruction. Unfortunately, no
                        data transmission or storage system can be guaranteed to be
                        100% secure. If you have any questions or concerns, please
                        contact us on the following link:
                        <a href="https://www.woorg.com/contact-us" target="_blank" rel="noreferrer">https://www.woorg.com/contact-us .</a>
                    </Description>
                    <SubTitle>
                        How long does Woorg retain Personal Information
                    </SubTitle>
                    <Description>
                        We store Personal Information associated with you until you
                        ask us to delete it. You can request deletion of your
                        Personal Information at any time by contacting us at
                        info@woorg.com. Please note that it may take a bit of time
                        to delete your Personal Information, and we may preserve it
                        for legal reasons or to prevent harm, including as described
                        in the "Does Woorg disclose any Personal Information to
                        third parties?" section.
                    </Description>
                    <SubTitle>
                        What rights do EEA visitors have under GDPR, and how can I
                        exercise them?
                    </SubTitle>
                    <Description>
                        Woorg processes personal data as described in this policy.
                        We rely on your consent to send promotional emails and to
                        place marketing cookies on your devices. In some cases,
                        Woorg may process personal data pursuant to legal obligation
                        or to protect your vital interests or those of another
                        person.
                        <br />
                        <br />
                        Individuals located in the European Economic Area (EEA) have
                        certain rights in respect to their personal information,
                        including the right to access, correct, or delete personal
                        data we process through your use of the site. If you're a
                        user based in the EEA, you can
                        <Container className="pt-4 ">
                            <Description>
                                -&nbsp;  Have your information corrected or deleted by
                                contacting us at info@woorg.com.
                                <br />
                                -&nbsp;  Object to us processing your information. You can
                                ask us to stop using your information, including
                                when we use your information to send you marketing
                                emails.
                                We only send marketing communications to
                                users located in the EEA with your prior consent,
                                and you may withdraw your consent at any time by
                                clicking the "unsubscribe" link found within Woorg
                                emails. Please note you may continue to receive
                                administrative account-related information, even if
                                you unsubscribe from marketing emails. <br />
                                -&nbsp;  Complaint to a regulator. If you're based in the
                                EEA and think that we haven't complied with data
                                protection laws, you have a right to lodge a
                                complaint with your local supervisory authority.
                            </Description>
                        </Container>
                    </Description>
                    <SubTitle>
                        California Online Privacy Protection Act Compliance
                    </SubTitle>
                    <Description>
                        Because we value your privacy we have taken the necessary
                        precautions to be in compliance with the California Online
                        Privacy Protection Act. We will not distribute your personal
                        information to outside parties without your consent.
                    </Description>
                    <SubTitle>
                        Children's Online Privacy Protection Act Compliance
                    </SubTitle>
                    <Description>
                        We do not collect any information from anyone under 5 years
                        of age. Our website, products, and services are all directed
                        at adults.
                    </Description>
                    <SubTitle>
                        Changes to Our Privacy Policy
                    </SubTitle>
                    <Description>
                        We may change this privacy policy from time to time. If we
                        decide to change our privacy policy, we will post those
                        changes on this page.
                    </Description>
                </Row>
            </Container>

        </MainSection>
    );
};

export default Privacypolicy;
