import styled, { themeGet, css, device } from "@theme/utils";
import bg from "../../data/images/project-info/Contact1.webp";
export const MainSection = styled.section`
    padding: 75px 0px;
    width: 100px;
    ${device.xlarge} {
        padding: 30px 0px;
    }
`;

export const Wrapper = styled.div`
    height: auto;
    padding: 20px;
    padding-top: 0;
    margin-top: 32px ${device.large} {

    }
    ${device.mobileview} {
        margin-top: 32px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -60px;
    margin-bottom: 10px;
    text-align: center;
    padding: 1.5rem;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50px;
    overflow: hidden;
    height: 500px ${device.medium} {
        background-size: 100%;
        border-radius: 30px;
        margin: 50px auto;
        height: 470px;
    }
    ${device.xlarge} {
        background-size: 100%;
        border-radius: 70px;
        height: 400px;
    }
`;

export const Title = styled.h1`
    font-family: "Ruda", sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
    line-height: 1.2;

    ${device.large} {
        font-size: 2.5rem;
        line-height: 1.2;
    }
`;

export const Description = styled.p`
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 15px;
    text-align: center;

    ${device.large} {
        font-size: 1.7rem;
        line-height: 22px;
    }
`;
