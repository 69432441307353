import { Link } from "gatsby";
import React from "react";
import { Wrapper, Description, Title, Container } from "./style";

const ProjectInfo = () => {
    return (
        <Wrapper>
            <Container>
                <Title>
                    Looking to Boost Your Brand & ROI with a Proactive,
                    Strategic Partner?
                </Title>
                <Description>
                    <b>Request a 360-degree FREE Business Strategic Plan</b>{" "}
                    with No-obligation to Scale your Business
                    <br /> and Increase your ROI with Woorg.
                </Description>
                <Link
                    to="/contact-us"
                    style={{
                        background: "#fff",
                        color: "#00cfff",
                        padding: "6px 30px",
                        borderRadius: "20px",
                        fontSize: "1.8rem",
                        fontWeight: "bold",
                        fontFamily: '"Ruda", sans-serif',
                    }}
                >
                    Contact Us
                </Link>
            </Container>
        </Wrapper>
    );
};

export default ProjectInfo;
